export type GeoRaw = {
	country?: string;
	region?: string;
	city?: string;
};

export type Geo = {
	country_name?: string;
	region_name?: string;
	city_name?: string;
};

export const useMapGeo = ({ country, region, city }: GeoRaw): Geo => ({
	...(country && { country_name: country }),
	...(region && { region_name: region }),
	...(city && { city_name: city }),
});
